.token{
    background: white;
    color: black;
    padding: 40px;
    text-align: center;

}

.tokenholder{
    line-height: .6em;
    font-size: 0.8em;
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;

}
h3{
    margin-top: -20px;
}

.individual{
    margin: 40px;
}
.tokenholder a{
    color: black;
    font-size: .7em;
}

.tokenholder p{
    font-size: .9em;
    margin-top: -10px;
}

.mintsuite{
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
}

.details{
    text-align: left;
    margin-left: 10px;
    font-size: 0.7em;
}

.individual img{
    max-width: 300px;
}
@media only screen and (max-width: 700px) {
    .tokenholder {
      display: block;
    }
    .mintsuite{
        display: block;
    }

    .details{
        margin: 30px;
    }
    .individual{
        margin: 30px 0px;
    }
  }