.Header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 40px 15%;
}

.Header img{
    max-height: 100px;
}

.Nav{
    display: flex;
    justify-content: space-between;
    width: 600px;
}

.Nav a{
    color: white;
    text-decoration: none;
}

.mobile{
    display: none;
}

@media only screen and (max-width: 700px) {
.Header{
    padding: 0;
}

.Nav{
    display: none;
}
.mobile{
    display: block;
}
  }