.head {
    position: fixed;
    width: 100%;
    z-index: 3;
    top: 0;
  }
  
  .head ul {
    margin: 0;
    padding: 0;
    list-style: none;
    overflow: hidden;

  }
  
  .head li a {
    display: block;
    padding: 20px 20px;
    text-decoration: none;
    color: white;
  }
  

  
  /* menu */
  
  .head .menu {
    clear: both;
    max-height: 0;
    transition: max-height .2s ease-out;
  }
  
  /* menu icon */
  
  .head .menu-icon {
    cursor: pointer;
    display: inline-block;
    float: right;
    padding: 28px 20px;
    position: relative;
    margin-bottom: -60px;
    user-select: none;
    background: black;
  }
  
  .head .menu-icon .navicon {
    background: white;
    display: block;
    height: 2px;
    position: relative;
    transition: background .2s ease-out;
    width: 18px;
  }
  
  .head .menu-icon .navicon:before,
  .head .menu-icon .navicon:after {
    background: white;

    content: '';
    display: block;
    height: 100%;
    position: absolute;
    transition: all .2s ease-out;
    width: 100%;
  }
  
  .head .menu-icon .navicon:before {
    top: 5px;
  }
  
  .head .menu-icon .navicon:after {
    top: -5px;
  }
  
  /* menu btn */
  
  .head .menu-btn {
    display: none;
  }
  
  .head .menu-btn:checked ~ .menu {
    max-height: 600px;
  }
  .menu-btn:checked{
      background: black;
  }
  .head .menu-btn:checked ~ .menu-icon .navicon {
    background: transparent;
  }
  
  .head .menu-btn:checked ~ .menu-icon .navicon:before {
    transform: rotate(-45deg);
  }
  
  .head .menu-btn:checked ~ .menu-icon .navicon:after {
    transform: rotate(45deg);
  }
  
  .head .menu-btn:checked ~ .menu-icon:not(.steps) .navicon:before,
  .head .menu-btn:checked ~ .menu-icon:not(.steps) .navicon:after {
    top: 0;
  }
  
  /* 48em = 768px */

  .menu{
      background: black;
  }
  
  @media (min-width: 48em) {
    .head li {
      float: left;
    }

    .head{
        width: 60%;
        position: relative;
    }
    
    .head li a {
      padding: 20px 30px;
    }
    .head .menu {
      clear: none;
      float: right;
      max-height: none;
    }
    .head .menu-icon {
      display: none;
    }
    .menu{
        background: none;
    }
  }
  