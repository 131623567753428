.Intro{
    Width: 100%;
    background: white;
    color: black;
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
}


.Intro .text{
    padding: 50px;
    text-align: center;
}

.red{
    color: #e5383b;
    font-size: 1.4em;
}

@media only screen and (max-width: 700px) {
    .Intro {
      display: block;
    }

    .Intro iframe{
        min-height: 400px;
    }
  }