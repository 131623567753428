.accordion__button:before {
    display: inline-block;
    content: '';
    height: 10px;
    width: 10px;
    margin-right: 12px;
    border-bottom: 2px solid currentColor;
    border-right: 2px solid currentColor;
    transform: rotate(
-45deg);
}

.accordion{
    margin: 50px;
    word-wrap: break-word;
}

.accordion__button[aria-expanded="true"]:before{
    transform: rotate(
        45deg);
        margin-top: -10px;
}

.accordion__panel{
    margin: 40px;
    font-size: 0.6em;
    line-height: 1.6em;
}

.faq h2{
    text-align: center;
    margin-top: 40px;
}

.accordion__button{
    cursor: pointer;
}

