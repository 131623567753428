html{
  scroll-behavior: smooth;}
  
body{
  padding: 0;
  margin: 0;
  font-family: "Rajdhani", sans-serif;
  font-size: 30px;
  font-weight: 700;
  color: white;
scroll-behavior: smooth;
}

#container{
  background-image: url('./images/bg-texture-01.jpg');
  background-size: cover;
  min-height: 100vh;
}

h1{
  margin: 0;
  font-size: 3em;
  letter-spacing: -.06em;
  margin-top: -10px;
  line-height: 1em;
}
.padding{
  padding: 40px 15%;
}

.large{
  font-size: 1.2em;
  color: white;
}

sub{
  color: #e5383b !important;
  letter-spacing: -.06em;
}

h2{
  margin-top: -10px;
}

button{
  background: #e5383b !important;
  border: 0;
  padding: 10px 20px;
}

#wallet-address{
word-wrap: break-word;
}

input[type="number"] {
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

.number-input {
  border: 0;
  display: inline-flex;
}

.number-input,
.number-input * {
  box-sizing: border-box;
}

.number-input button {
  outline:none;
  -webkit-appearance: none;
  background-color: transparent;
  border: none;
  align-items: center;
  justify-content: center;
  width: 3rem;
  height: 3rem;
  cursor: pointer;
  margin: 0;
  position: relative;
  box-shadow: 0px 0px 1px #474747;
}

.number-input button:before,
.number-input button:after {
  display: inline-block;
  position: absolute;
  content: '';
  width: 1rem;
  height: 2px;
  background-color: #212121;
  transform: translate(-50%, -50%);
}
.number-input button.plus:after {
  transform: translate(-50%, -50%) rotate(90deg);
}

.number-input input[type=number] {
  font-family: sans-serif;
  max-width: 5rem;
  padding: .5rem;
  border: none;
  border-width: 0 2px;
  font-size: 2rem;
  height: 3rem;
  font-weight: bold;
  text-align: center;
  color:#e5383b;
}
a{
  color: #e5383b;
}
i{
  color:#e5383b;
  font-style: normal;
}

select{
  width: 400px;
  padding: 10px 20px;
}

.notification .message{
  font-size: 0.5em;
  line-height: 1.1em;
  word-wrap: break-word;
}

.largebutton{
  width: 70%;
  padding: 20px;
  color: white;
  font-size: 1em;
  margin-top: 20px;
  border: 2px solid white;
}

.mintdate{
  margin-top: 30px;
}
.mintdate a{
  color: white;
}

.centeredtext{
  text-align: center;
}

.submit-container{
  text-align: center;
}

@media only screen and (max-width: 700px) {
  body{
      font-size: 20px;
  }
    }