.Footer{
    background: #e5383b;
    padding: 30px;
    text-align: center;
}

.white{
    background: white !important;
}
#SOCIALS{
    margin-top: 20px;
}
#SOCIALS a{
    margin: 15px;
}

@media only screen and (max-width: 700px) {
.Header{
    padding: 0;
}
  }